<template>
  <div class="mediaReviewPhotoSessionOverview">
    <div
      class="p-2 border mb-2 mr-2 text-center rounded"
    >
      <div class="imagePlaceholderWrapper rounded mb-1">
        <!-- <img
          v-if="run"
          :src="getImage(run)"
          class="previewImage rounded"
          loading="lazy"
        > -->
        <video-player
          v-if="run && playerOptions.sources[0] != null"
          id="videoPlayerOne"
          ref="videoRef"
          :playsinline="true"
          :options="playerOptions"
          class="mediaReview"
        />
        <ImagePlaceholderSvg
          v-else
          :height="200"
          :width="350"
          :media-preview="true"
          class="previewImage rounded w-100 h-100"
        />
      </div>
      <div>
        <label class="bold mr-2 mb-0">{{ $t('created') }}:</label>
        <span v-if="run && run.createDate">{{ dateTime_dateTimeSeconds(run.createDate) }}</span>
        <font-awesome-icon
          v-if="run && playerOptions.sources[0] != null"
          class="float-right mt-1 mr-2"
          :icon="mute ? 'volume-mute' : 'volume-up'"
          @click="muteVideo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { videoPlayer } from 'vue-video-player';

export default {
  name: "MediaReviewPhotoSessionOverview",
  components: {
    ImagePlaceholderSvg: () =>
      import("@/components/Base/ImagePlaceholderSvg.vue"),
    videoPlayer
  },
  mixins: [dateTimeMixin],
  props: {
    run: {
      type: Object,
      required: false,
      default: null
    },
    index: {
      type: Number,
      required: false,
      default: -1
    },
    isPlaying: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      mute: true,
      playerOptions: {
        autoplay: this.isPlaying,
        responsive: true,
        muted: true,
        preload: 'none',
        language: 'en',
        playbackRates: [2],
        sources: [null],
        poster: null,
        fluid: false,
        loop: false,
        width: 350,
        height: 200,
        controls: true
      }
    }
  },
  watch: {
    isPlaying (val) {
      if(this.$refs.videoRef) {
        if(val) {
          this.$refs.videoRef.player.play();
        }
        else {
          this.$refs.videoRef.player.pause();
        } 
      }
    }
  },
  mounted () {
    if(this.$refs.videoRef) {
      this.$refs.videoRef.player.playbackRate(2);
    }
  },
  created () { 
    if(this.run) {
      this.playerOptions.poster = this.run.snapshotUrl;
      this.playerOptions.sources = [{
        type: "video/mp4",
        src: this.run.mobileVideoUrl
      }]; 
    }
  },
  methods: {
    muteVideo () {
      this.mute = !this.mute;
      this.$refs.videoRef.$el.querySelector(".vjs-mute-control").click();
    }    
  }
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.imagePlaceholderWrapper {
  position: relative;
  width: 350px;
  overflow: hidden;
  height: 200px;
}
.imagePlaceholderWrapper > #videoPlayerOne {
  position: absolute;
  width: 350px;
  height: auto;
  min-width: auto;
  min-height: 100%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>

<style>
.mediaReview div video {
  width: 350px;
  height: 200px;
}

.imagePlaceholderWrapper > #videoPlayerOne .video-js .vjs-poster {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-color: #000;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
}

.imagePlaceholderWrapper > #videoPlayerOne .vjs-has-started .vjs-poster {
    display: none;
}
</style>
